<template>
  <div class="user-team-management">
    <div class="grid-toolbar">
      <div class="grid-toolbar-title">
        <p>User Team Management</p>
      </div>
      <div class="grid-toolbar-filters">
        <div class="toolbar-column">
          <b-button
            class="action-button export-csv-button"
            size="is-small"
            @click="exportCSVAndDownload"
          >
            <b-icon icon="file" size="is-small" class="toolbar-icons"> </b-icon>
            &nbsp; Export CSV
          </b-button>
        </div>
        <span class="k-textbox k-grid-search k-display-flex">
          <k-input
            :style="{ width: '430px' }"
            :placeholder="'Search by Email or Reports To or Teams'"
            :value="searchWord"
            :inputPrefix="'prefix'"
            @input="filterGridData"
          >
            <template v-slot:prefix>
              <span class="k-input-icon k-icon k-i-search"></span>
            </template>
          </k-input>
        </span>
      </div>
    </div>
    <Grid
      ref="grid"
      :style="{ height: '100%', minHeight: '300px' }"
      :data-items="gridData"
      :edit-field="'inEdit'"
      :resizable="true"
      :sortable="true"
      :sort="sort"
      :pageable="pageable"
      :take="take"
      :skip="skip"
      :total="total"
      :loader="loading"
      @itemchange="itemChange"
      @datastatechange="dataStateChange"
      @sortchange="sortChangeHandler"
      @pagechange="pageChangeHandler"
      :columns="columns"
    >
      <template v-slot:readOnlyEmail="{ props }">
        <td v-if="!props.dataItem.inEdit || !props.dataItem.fromInsert">
          {{ props.dataItem.userEmail }}
        </td>
        <td v-else>
          <dropdownlist
            :style="{ width: '100%' }"
            @change="userEmailDropDownChange($event, props.dataItem)"
            :value="props.dataItem.userEmail"
            :data-items="userList"
          ></dropdownlist>
        </td>
      </template>
      <template v-slot:readOnlyReportToEmail="{ props }">
        <td v-if="!props.dataItem.inEdit">
          {{ props.dataItem.reportToEmail }}
        </td>
        <td v-else>
          <dropdownlist
            :style="{ width: '100%' }"
            @change="reportToEmailDropDownChange($event, props.dataItem)"
            :value="props.dataItem.reportToEmail"
            :data-items="pmUserList"
          ></dropdownlist>
        </td>
      </template>
      <template v-slot:dropDownCellTeams="{ props }">
        <td v-if="!props.dataItem.inEdit">
          {{ props.dataItem.teamName }}
        </td>
        <td v-else>
          <dropdownlist
            :style="{ width: '100%' }"
            @change="teamsDropDownChange($event, props.dataItem)"
            :value="props.dataItem.teamName"
            :data-items="teamList"
          ></dropdownlist>
        </td>
      </template>
      <template v-slot:dropDownCellWorkType="{ props }">
        <td v-if="!props.dataItem.inEdit">
          {{ props.dataItem.workTypeName || 'Data Enrichment' }}
        </td>
        <td v-else>
          {{ props.dataItem.workTypeName || 'Data Enrichment' }}
        </td>
      </template>
      <template v-slot:roleNameCellItem="{ props }">
        <td>
          {{ props.dataItem.roleName }}
        </td>
      </template>
      <template v-slot:actionCell="{ props }">
        <custom
          :data-item="props.dataItem"
          @edit="edit"
          @save="save"
          @remove="remove"
          @cancel="cancel"
        />
      </template>
      <grid-toolbar>
        <kbutton
          title="Add User Association"
          @click="insert"
          class="k-grid-add"
        >
          Add User Association
        </kbutton>
        <kbutton
          v-if="hasItemsInEdit"
          title="Cancel current changes"
          @click="cancelChanges"
        >
          Cancel current changes
        </kbutton>
      </grid-toolbar>
      <grid-norecords> No data available. </grid-norecords>
    </Grid>

    <ConfirmationDialog
      :visible="dialog.visible"
      :title="dialog.title"
      :cancelButtonText="dialog.cancelButtonText"
      :confirmButtonText="dialog.confirmButtonText"
      :closeDialog="closeDialog"
      :triggerFunction="dialog.triggerFunction"
    >
      <template v-slot:text>
        <div class="body-text">
          Are you sure you wants to remove below association ?
        </div>
        <div class="dialog-body">
          <p class="label-text">User Email :</p>
          <p class="text">{{ userEmail }}</p>
        </div>
        <div class="dialog-body">
          <p class="label-text">Role :</p>
          <p class="text">{{ roleName }}</p>
        </div>
        <div class="dialog-body">
          <p class="label-text">Reports To :</p>
          <p class="text">{{ reportsTo }}</p>
        </div>
        <div class="dialog-body">
          <p class="label-text">Team :</p>
          <p class="text">{{ team }}</p>
        </div>
      </template>
    </ConfirmationDialog>
  </div>
</template>
<script>
import { Grid, GridToolbar, GridNoRecords } from '@progress/kendo-vue-grid'
import { Button } from '@progress/kendo-vue-buttons'
import { process } from '@progress/kendo-data-query'
import { DropDownList } from '@progress/kendo-vue-dropdowns'
import { mapActions, mapGetters, mapState } from 'vuex'
import { Input } from '@progress/kendo-vue-inputs'
import { isEqual, isEmpty } from 'lodash'
import Snackbar from '@/components/Snackbar'
import CommandCell from './CommandCell.vue'
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import { downloadCSV } from '@/util/util'
import moment from 'moment'

export default {
  components: {
    Grid: Grid,
    'grid-toolbar': GridToolbar,
    'grid-norecords': GridNoRecords,
    custom: CommandCell,
    kbutton: Button,
    dropdownlist: DropDownList,
    'k-input': Input,
    ConfirmationDialog
  },
  data() {
    return {
      gridData: [], // first page data
      updatedData: [], // total data
      editID: null,
      searchWord: '',
      customFilter: null,
      filter: null,
      sort: null,
      dialog: {
        visible: false,
        title: 'Please confirm',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      },
      pageable: {
        buttonCount: 6,
        info: true,
        type: 'numeric',
        pageSizes: [10, 50, 100, 200],
        previousNext: true
      },
      prevReportToEmail: '',
      roleName: '',
      userEmail: '',
      reportsTo: '',
      team: '',
      skip: 0,
      take: 10,
      total: 0,
      loading: false,
      columns: [
        {
          cell: 'readOnlyEmail',
          field: 'userEmail',
          title: 'Email',
          width: '200px'
        },
        {
          cell: 'roleNameCellItem',
          field: 'roleName',
          title: 'Role'
        },
        {
          cell: 'readOnlyReportToEmail',
          field: 'reportToEmail',
          title: 'Reports To',
          sortable: false
        },
        {
          cell: 'dropDownCellTeams',
          field: 'teamName',
          title: 'Teams',
          width: '200px',
          sortable: false
        },
        {
          cell: 'dropDownCellWorkType',
          field: 'workTypeName',
          title: 'Worktype',
          width: '160px'
        },
        { cell: 'actionCell', filterable: false, width: '260px', inEdit: false }
      ],
      refreshTheGrid: false
    }
  },
  computed: {
    ...mapState('userManagement', [
      'userAssociations',
      'userList',
      'pmUserList',
      'teamList'
    ]),
    ...mapState('user', ['userDetails']),
    hasItemsInEdit() {
      return this.gridData.filter((p) => p.inEdit).length > 0
    }
  },

  watch: {
    userAssociations() {
      this.updatedData = JSON.parse(JSON.stringify(this.userAssociations))
      this.cloneUpdatedData = this.updatedData
      this.skip = 0
      this.take = 10
      this.sort = null
      this.searchWord = ''
      this.customFilter = null
      this.total = this.updatedData.length
      this.gridData = this.getData(this.updatedData)
    },

    async updatedData() {
      this.updatedData.forEach((dataItem) => {
        // when in edit mode do not refresh
        if ('inEdit' in dataItem && dataItem.inEdit) {
          this.refreshTheGrid = false
        }
      })

      if (
        !isEqual(this.userAssociations, this.updatedData) &&
        this.refreshTheGrid
      ) {
        this.loading = true
        await this.getUserAssociations()
        this.refreshTheGrid = false
        this.loading = false
      }
    }
  },

  created() {
    this.gridData = this.getData(this.updatedData)
    this.total = this.gridData.length
  },

  async mounted() {
    this.loading = true
    await this.getTeamList()
    await this.getUserList()
    await this.getUserAssociations()
    this.updatedData = JSON.parse(JSON.stringify(this.userAssociations))
    this.total = this.updatedData.length
    this.gridData = this.getData(this.updatedData)
    this.loading = false
  },

  methods: {
    ...mapActions('userManagement', [
      'getUserAssociations',
      'getUserList',
      'getTeamList',
      'updateUserAssociations',
      'addUserAssociations',
      'deleteUserAssociation'
    ]),

    async exportCSVAndDownload() {
      // Extract the field names from the columns array
      const columnFields = this.columns.map((column) => column.field)
      let gridData = this.cloneUpdatedData
      if (this.customFilter) {
        // Filter your dataItems based on the custom filter.
        gridData = this.updatedData.filter((item) => {
          return this.customFilter.filters.some((filter) => {
            return item[filter.field]
              .toString()
              .toLowerCase()
              .includes(filter.value)
          })
        })
      }
      // Extract the headers (titles) from columns
      const headers = this.columns.map((column) => column.title)

      // Create CSV content
      let csvContent = headers.join(',') + '\n'

      gridData.forEach((row) => {
        // Filter the row to only include data from the selected fields
        const rowData = columnFields.map((field) => row[field])
        csvContent += rowData.join(',') + '\n'
      })
      const fileName = `user_team_managment-data_${moment().format(
        'DD-MM-YYYY_HH:mm'
      )}.csv`

      downloadCSV(csvContent, fileName)
    },

    sortChangeHandler(e) {
      this.sort = e.sort
      this.total = this.updatedData.length
      this.updateGridData(this.skip, this.take)
    },

    pageChangeHandler(e) {
      this.skip = e.page.skip
      this.take = e.page.take
      this.updateGridData(this.skip, this.take)
    },

    itemChange(e) {
      if (e.dataItem.localId) {
        const index = this.updatedData.findIndex(
          (p) => p.localId === e.dataItem.localId
        )
        const updated = Object.assign({}, this.updatedData[index], {
          [e.field]: e.value
        })
        this.updatedData.splice(index, 1, updated)
      } else {
        e.dataItem[e.field] = e.value
      }
    },

    insert() {
      // always add in the first page
      this.skip = 0
      this.take = 10
      this.sort = null
      this.searchWord = ''
      this.customFilter = null

      const updatedData = this.updatedData.slice()
      const dataItem = {
        inEdit: true,
        fromInsert: true,
        userEmail: null,
        reportToEmail: null,
        teamName: null
      }
      // add the id of the item on insert
      const item = this.update(updatedData, dataItem, false)
      const newUserAssociations = this.updatedData.slice()
      newUserAssociations.unshift(item)
      this.update(newUserAssociations, item, false)
      this.updatedData = newUserAssociations
      this.total = this.updatedData.length
      this.gridData = this.getData(this.updatedData)
    },

    edit(e) {
      this.refreshTheGrid = false // do not refresh grid when in edit mode
      const index = this.updatedData.findIndex(
        (data) => data.localId === e.dataItem.localId // find index using user association id
      )
      const updated = Object.assign({}, this.updatedData[index], {
        inEdit: true
      })
      this.updatedData.splice(index, 1, updated)
      if (this.searchWord) {
        this.updateGridData(this.skip, this.take)
      } else {
        this.gridData = this.getData(this.updatedData)
        this.total = this.updatedData.length
      }
    },

    async save(e) {
      this.loading = true
      const index = this.gridData.findIndex(
        (p) => p.localId === e.dataItem.localId
      )
      const newItem = this.gridData[index]
      const updated = Object.assign(
        this.update(this.gridData.slice(), newItem, false),
        { inEdit: undefined }
      )

      const {
        userAssociationId,
        reportToEmail,
        teamName,
        userEmail,
        workTypeName,
        fromInsert
      } = updated

      const isAnyFieldEmpty = [reportToEmail, teamName, userEmail].some(
        (item) => {
          return isEmpty(item)
        }
      )

      if (isAnyFieldEmpty) {
        this.loading = false
        Snackbar({
          message: 'Please fill all the field.',
          type: 'is-warning'
        })
        return
      }

      if (fromInsert) {
        const defaultWorkTypeName = 'Data Enrichment'
        await this.addUserAssociations({
          reportToEmail,
          teamName,
          userEmail,
          workTypeName: defaultWorkTypeName,
          loggedInUserId: this.userDetails.userId
        })
        Snackbar({
          message: 'User association added successfully',
          type: 'is-success'
        })
      } else {
        await this.updateUserAssociations({
          userAssociationId,
          reportToEmail,
          teamName,
          userEmail,
          workTypeName,
          loggedInUserId: this.userDetails.userId
        })
      }

      this.refreshTheGrid = true
      this.gridData.splice(index, 1, updated)
      const updateDataIndex = this.updatedData.findIndex(
        (p) => p.localId === e.dataItem.localId
      )
      this.updatedData.splice(updateDataIndex, 1, updated)
      this.loading = false
    },

    update(data, item, remove) {
      let updated
      let index = data.findIndex(
        (p) => item.localId && p.localId === item.localId
      )
      if (index >= 0) {
        updated = Object.assign({}, item)
        data[index] = updated
      } else {
        const maxId = data.reduce(
          (max, p) => (p.localId > max ? p.localId : max),
          0
        )
        const newId = maxId + 1
        updated = Object.assign({}, item, { localId: newId })
        data.unshift(updated)
        index = 0
      }

      if (remove) {
        data = data.splice(index, 1)
      }

      return data[index]
    },

    cancel(e) {
      // don't remove if it is in edit mode
      if (!e.dataItem.fromInsert && e.dataItem.localId) {
        const index = this.updatedData.findIndex(
          (p) => p.localId === e.dataItem.localId
        )
        const updateDataIndex = this.updatedData.findIndex(
          (p) => p.localId === e.dataItem.localId
        )
        const updated = Object.assign(this.updatedData[updateDataIndex], {
          reportToEmail: this.cloneUpdatedData[updateDataIndex].reportToEmail,
          teamName: this.cloneUpdatedData[updateDataIndex].teamName,
          userEmail: this.cloneUpdatedData[updateDataIndex].userEmail,
          inEdit: undefined,
          fromInsert: undefined
        })
        this.updatedData.splice(index, 1, updated)
      } else {
        // remove if it is not in edit mode
        const index = this.updatedData.findIndex(
          (p) => JSON.stringify(e.dataItem) === JSON.stringify(p)
        )

        this.updatedData.splice(index, 1)
      }

      if (this.searchWord) {
        this.updateGridData(this.skip, this.take)
      } else {
        this.gridData = this.getData(this.updatedData)
        this.total = this.updatedData.length
      }
    },

    remove(data) {
      this.dialog.visible = true
      this.userEmail = data.dataItem.userEmail
      this.roleName = data.dataItem.roleName
      this.reportsTo = data.dataItem.reportToEmail
      this.team = data.dataItem.teamName
      this.dialog.triggerFunction = async () => {
        const userAssociationId = data.dataItem.userAssociationId
        this.loading = true
        await this.deleteUserAssociation(userAssociationId) // this is the function that will be triggered on the confirm button
        await this.getUserAssociations()
        this.loading = false
      }
    },
    closeDialog() {
      this.dialog.visible = false
    },

    async cancelChanges() {
      this.loading = true
      this.skip = 0
      this.take = 10
      this.sort = null
      this.searchWord = ''
      this.customFilter = null
      this.updatedData = this.cloneUpdatedData
      this.total = this.updatedData.length
      this.gridData = this.getData(this.updatedData)
      this.loading = false
    },

    getData(data) {
      return process(data, {
        take: this.take,
        skip: this.skip,
        sort: this.sort,
        filter: this.filter
      }).data
    },

    createAppState(dataState) {
      this.take = dataState.take
      this.skip = dataState.skip
      this.filter = dataState.filter
      this.sort = dataState.sort
      this.total = this.updatedData.length
      this.gridData = this.getData(this.updatedData)
    },

    dataStateChange(event) {
      this.createAppState(event.data)
    },

    teamsDropDownChange(e, dataItem) {
      const updatedData = this.updatedData.slice()
      const item = this.update(updatedData, dataItem, false)
      item.teamName = e.target.value
      this.updatedData = updatedData
      if (this.searchWord) {
        this.updateGridData(this.skip, this.take)
      } else {
        this.gridData = this.getData(updatedData)
      }
    },

    userEmailDropDownChange(e, dataItem) {
      const updatedData = this.updatedData.slice()
      const item = this.update(updatedData, dataItem, false)
      item.userEmail = e.target.value
      this.updatedData = updatedData
      if (this.searchWord) {
        this.updateGridData(this.skip, this.take)
      } else {
        this.gridData = this.getData(this.updatedData)
      }
    },

    reportToEmailDropDownChange(e, dataItem) {
      const updatedData = this.updatedData.slice()
      const item = this.update(updatedData, dataItem, false)
      item.reportToEmail = e.target.value
      this.updatedData = updatedData
      if (this.searchWord) {
        this.updateGridData(this.skip, this.take)
      } else {
        this.gridData = this.getData(this.updatedData)
      }
    },

    updateGridData(skip = 0, take = 10) {
      let filteredData = this.updatedData
      if (this.customFilter) {
        // Filter your dataItems based on the custom filter before sending them to the grid.
        filteredData = this.updatedData.filter((item) => {
          return this.customFilter.filters.some((filter) => {
            return item[filter.field]
              .toString()
              .toLowerCase()
              .includes(filter.value)
          })
        })
      }

      this.skip = skip
      this.take = take
      this.total = filteredData.length
      this.gridData = this.getData(filteredData)
    },

    filterGridData(event) {
      this.updatedData = this.cloneUpdatedData
      this.searchWord = event.value
      this.customFilter = this.constructFilter(event.value.toLowerCase(), [
        'userEmail',
        'reportToEmail',
        'teamName'
      ])
      this.updateGridData()
    },

    constructFilter(inputValue, filterFields) {
      const filters = []
      const isValueArray = Array.isArray(inputValue)
      const isFieldArray = Array.isArray(filterFields)

      const addFilters = (field, value) => {
        filters.push({
          field: field,
          operator: 'contains',
          value: value,
          ignoreCase: true
        })
      }

      if (isValueArray) {
        inputValue.forEach((value) => {
          if (isFieldArray) {
            filterFields.forEach((field) => addFilters(field, value))
          } else {
            addFilters(filterFields, value)
          }
        })
      } else {
        if (isFieldArray) {
          filterFields.forEach((field) => addFilters(field, inputValue))
        } else {
          addFilters(filterFields, inputValue)
        }
      }

      return {
        logic: 'or',
        filters: filters
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-team-management {
  .k-grid-add {
    background-color: #535eeb !important;
    color: #fff !important;
    border: 1px solid #535eeb !important;
  }


  .grid-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 26px;
    border: none;

    .grid-toolbar-title {
      p {
        text-align: left;
        font-weight: 600;
        font-style: normal;
        font-size: 28px;
        line-height: 15px;
        font-family: Quicksand;
        letter-spacing: 0px;
        color: #00218a;
        opacity: 1;
        line-height: 1.5rem;
      }
    }

    .grid-toolbar-filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      .export-csv-button {
        border: 1px solid #444444;
        color: #444444;
        &:hover {
          background: #444444;
          border: 1px solid #444444;
          color: #fff;
        }
      }

      .dropdown-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.6rem;
        width: 100%;

        ::v-deep .data-source-container {
          display: flex;
          align-items: center;

          .top-label {
            font-size: 14px;
            line-height: 20px;
            font-family: Quicksand;
            font-weight: 600;
            font-style: normal;
            color: #7d7d7d;
            margin-right: 0.6rem;
            margin-top: 0.3rem;
          }
          .dropdown-wrap {
            // height: 40px;
            .data-source-header {
              background-color: #ffffff;
              border: 1px solid #cccccc;

              // color: black;
              .button-container {
                .ellipsis {
                  color: black;
                  font-size: 14px;
                  line-height: 20px;
                  font-family: Quicksand;
                  font-weight: 600;
                  font-style: normal;
                }
                svg path {
                  stroke-width: 1;
                  stroke: black;
                }
              }
            }

            .data-source-abs {
              background: #ffffff 0% 0% no-repeat padding-box;
              color: #000000;

              .custom-checkbox {
                p {
                  color: #000000;
                }
              }

              .custom-checkbox:hover {
                background: rgba(196, 200, 248, 0.3) 0% 0% no-repeat padding-box;
              }
            }
          }
        }
      }

      ::v-deep .k-grid-search {
        height: 36px;
        width: 100%;
        .k-input {
          border: 1px solid #cccccc;

          input {
            // height: 44px;
            font-size: 13px;
            line-height: 20px;
            font-weight: 600;
            font-family: Quicksand;
            font-style: normal;
          }

          .k-input-inner {
            margin-left: 0.1rem;
          }

          .k-input-prefix {
            margin-left: 0.5rem;
            color: #cccccc;
          }
        }
      }
    }
  }

  ::v-deep .k-grid-header {
    width: 100%;
    .k-table-row {
      .k-table-th {
        background: #dfdfdf;
        font-size: 16px;
        color: #000000;
        line-height: 19px;
      }
    }
    .k-header > div > a {
      top: 4px;
    }
    .k-header.active > div > a {
      color: #fff;
      background-color: #ff6358;
      top: 4px;
    }
  }
}

.body-text {
  font-family: Quicksand;
  font-size: 15px;
  font-weight: 600;
  padding: 2px;
  letter-spacing: 0.16px;
  opacity: 1;
  margin-bottom: 3px;
}

.dialog-body {
  height: auto;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .label-text {
    width: 26%;
    font-family: Quicksand;
    font-weight: 500;
    font-size: 14px;
    padding: 4px;
    letter-spacing: 0.16px;
    opacity: 1;
  }
  .text {
    width: 72%;
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 500;
    padding: 4px;
    letter-spacing: 0.16px;
    opacity: 1;
  }
}
</style>
